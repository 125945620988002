import store from '@/store'
import helper from './helper'

const { getFormattedDate } = helper()

const bodyForBooking = orderItems => {
  // (C) CREATE HTML TABLE
  // (C1) HTML TABLE STRING
  // eslint-disable-next-line operator-linebreak
  let bodyHTML =
    '<head><style>table, th, td {border: 0px solid black;border-collapse: collapse;}th {text-align: left;}</style></head><table style="width: 100%;"><tr style="text-align: left;"><th>Name</th><th>ID</th><th>Stadt</th><th>Strasse</th><th>Mietbeginn</th><th>Mietende</th><th>Preis/Monat</th></tr><tr>'
  // (C2) LOOP THROUGH ARRAY & GENERATE ROWS-CELLS
  const perrow = 7 // 2 CELLS PER ROW
  orderItems.forEach((orderItem, i) => {
    // "NORMAL" CELL

    const location = store.getters.locationById(
      Number(orderItem.storage.location)
    )

    bodyHTML += `<td>${orderItem.storage.name}</td>`
    bodyHTML += `<td>${orderItem.storage.storageIdent}</td>`
    bodyHTML += `<td>${location.city}</td>`
    bodyHTML += `<td>${location.street} ${location.streetNumber}</td>`
    bodyHTML += `<td>${getFormattedDate(orderItem.rentStart)}</td>`
    bodyHTML += `<td>${getFormattedDate(orderItem.rentEnd)}</td>`
    bodyHTML += `<td>${orderItem.price} €</td>`

    // BREAK INTO NEXT ROW
    const next = i + 1
    if (next % perrow === 0 && next !== orderItems) {
      bodyHTML += '</tr><tr>'
    }
  })

  // (C3) CLOSE TABLE
  bodyHTML += '</tr></table>'

  // eslint-disable-next-line operator-linebreak
  // bodyHTML +=
  //   '<br /><p>Die Lager sind mit einem Zahlenschloss gesichert. Nach dem erstmaligen Öffnen Ihres Lagers, ersetzen Sie bitte das Zahlenschloss mit dem Schlüsselschloss welches sich in Ihrem Lager befindet. Das Zahlenschloss platzieren Sie bitte im inneren Ihres Lagers.</p><br />'
  // orderItems.forEach(orderItem => {
  //   // Rows
  //   bodyHTML += `<tr>Lager: ${orderItem.storage.storageIdent}</tr><br />`
  //   bodyHTML += `<tr>Zahlenschloss: ${orderItem.storage.lockPin}</tr>`
  // })

  return bodyHTML
}

const bodyForInquiry = storageItems => {
  // Your existing code
  // (C) CREATE HTML TABLE
  // (C1) HTML TABLE STRING
  // eslint-disable-next-line operator-linebreak
  let bodyHTML =
    '<head><style>table, th, td {border: 0px solid black;border-collapse: collapse;}th {text-align: left;}</style></head><table style="width: 100%;"><tr style="text-align: left;"><th>Name</th><th>ID</th><th>Stadt</th><th>Strasse</th><th>Mietbeginn</th><th>Mietende</th><th>Preis/Monat</th></tr><tr>'
  // (C2) LOOP THROUGH ARRAY & GENERATE ROWS-CELLS
  const perrow = 7 // 2 CELLS PER ROW

  // if (emailType === 'booking') {
  // eslint-disable-next-line operator-linebreak

  storageItems.forEach((storage, i) => {
    // "NORMAL" CELL
    console.log(
      '🚀 ~ file: emailStoragesBody.js:34 ~ storageItems.forEach ~ storage:',
      storage
    )

    const location = store.getters.locationById(Number(storage.location))

    bodyHTML += `<td>${storage.name}</td>`
    bodyHTML += `<td>${storage.storageIdent}</td>`
    bodyHTML += `<td>${location.city}</td>`
    bodyHTML += `<td>${location.street} ${location.streetNumber}</td>`
    bodyHTML += `<td>${getFormattedDate(storage.rentStart)}</td>`
    bodyHTML += `<td>${getFormattedDate(storage.rentEnd)}</td>`
    bodyHTML += `<td>${storage.price} €</td>`

    // BREAK INTO NEXT ROW
    const next = i + 1
    if (next % perrow === 0 && next !== storageItems) {
      bodyHTML += '</tr><tr>'
    }
  })

  // (C3) CLOSE TABLE
  bodyHTML += '</tr></table>'

  return bodyHTML
}

export default function emailStoragesBody(emailItems, emailType) {
  console.log('🚀 ~ file: emailStoragesBody.js:95 ~ emailItems:', emailItems)
  console.log('🚀 ~ file: emailStoragesBody.js:96 ~ emailType:', emailType)
  if (emailType === 'booking') {
    return bodyForBooking(emailItems)
  }
  if (emailType === 'offer') {
    return bodyForInquiry(emailItems)
  }
  return '<p>Fehler bei der Erstellung des E-Mail-Textes. Bitte kontaktieren Sie uns.</p>'
}
