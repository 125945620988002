export default {
  firstName: '',
  lastName: '',
  message: '',
  email: '',
  salutation: '',
  street: '',
  streetNumber: '',
  city: '',
  zipCode: '',
  phoneNumber: '',
  leadSource: '',
  storageLocation: '',
  estimatedStartRent: '',
  estimatedEndRent: '',
  company: '',
  billingAddressDiffers: false,
  storages: [],
  offerDate: '',
  created_by: '',
  updated_by: '',
  country: '',
  isActive: true,
  inquiry: '',
  customerSignatureImage: '',
}
