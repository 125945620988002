<template lang="">
  <div>
    <div style="margin: 50px">
      <!-- {{ storage }} {{ index }} -->
      <div>
        <p class="MsoNormal" />
        <p class="MsoNormal">
          <span>Zwischen (im folgenden „Vermieter“ genannt) </span>
        </p>
        <p class="MsoNormal">
          <b>
            <span>&nbsp;</span>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <span
              style="
                font-size: 10pt;
                font-family: 'Arial', sans-serif;
                color: black;
              "
              >GSM Lagermeister GmbH&amp;Co.KG,Rudolf Diesel Str.29-31,D-48157
              Münster,mobil:
            </span>
          </b>
          <span
            lang="SV"
            style="
              font-size: 10pt;
              font-family: 'Arial', sans-serif;
              color: black;
            "
            >01621305655,</span
          >
        </p>
        <p class="MsoNormal">
          <span
            lang="SV"
            style="
              font-size: 9pt;
              font-family: 'Arial', sans-serif;
              color: black;
            "
            >@Mail:
            <a href="mailto:der-lagermeister@web.de">der-lagermeister@web.de</a>
          </span>
          <b>
            <span
              style="
                font-size: 9pt;
                font-family: 'Arial', sans-serif;
                color: black;
              "
            >
              und</span
            >
          </b>
        </p>
        <div
          style="font-size: 9pt; font-family: 'Arial', sans-serif; color: black"
        >
          <b>
            {{ this.offer.firstName }}
            {{ this.offer.lastName }} ,{{ this.offer.street }}
            {{ this.offer.streetNumber }}, {{ this.offer.phoneNumber }},
            {{ this.offer.email }}</b
          >
        </div>
        <p class="MsoNormal">(im Folgenden „Kunde“ oder „Mieter“ genannt)</p>
        <p class="MsoNormal">
          <span>wird der folgende Lagerraum-Mietvertrag geschlossen:</span>
        </p>
        <p class="MsoNormal" style="text-align: justify; text-autospace: none">
          <b>
            <u>
              <span>1.) Gegenstand des Mietvertrags</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <span>Es wird in dem auf dem folgenden Grundstück: </span>
          <span>Rudolf Diesel Str.29, 48157 Münster befindlichen Lagerbox</span>
        </p>
        <p
          style="font-size: 9pt; font-family: 'Arial', sans-serif; color: black"
        />
        <b
          v-if="getStorage()"
          style="font-size: 9pt; font-family: 'Arial', sans-serif; color: black"
        >
          <!-- Display storage information -->

          ID: {{ getStorage().storageIdent }} | Bezeichnung:
          {{ getStorage().name }} | Größe:
          {{ getStorage().storageSpace }} m&#178; | Preis:
          {{ getStorage().price.toFixed(2) }} € | Rabatt:
          {{ getStorage().discount }} € | Summe/Monat:
          {{ getStorageSumPrice(getStorage()) }} €
        </b>
        <p class="MsoNormal">vermietet.</p>
        <p class="MsoNormal">
          <span>(im Folgenden „Mietsache“ genannt) </span>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>
                <span style="text-decoration: none">&nbsp;</span>
              </span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>2.) Mietzweck</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <span>Die Anmietung erfolgt ausschließlich als Lager.</span>
          </b>
          <span>
            Der Mieter verpflichtet sich, die Mietsache ausschließlich zu diesem
            Zweck zu nutzen. Der Mieter verpflichtet sich des weiteren, die
            Mietsache nur so zu nutzen, dass hieraus keine Umweltschäden und
            keine Gefahren für Rechtsgüter des Vermieters oder Dritter entstehen
            können. Insbesondere ist es ihm untersagt, feuer- oder
            explosionsgefährliche, strahlende, zur Selbstentzündung neigende,
            giftige, ätzende, Wasser gefährdende oder übel riechende Stoffe zu
            lagern. Das Lagerverbot gilt weiterhin für Güter, die verderben,
            verfaulen, Ungeziefer anlocken können, sowie darüber hinaus für
            lebende Tiere und Pflanzen.
          </span>
          <span
            >Eine Nutzung zu Wohn- oder Arbeitszwecken ist nicht gestattet.
            <span style="color: black"
              >Die – auch vorübergehende - Nutzung der Mietsache zum
              Aufenthalt(Übernachtung) von Personen </span
            >und/oder zur Aufbewahrung von Tieren zu nutzen
            <span style="color: black">ist untersagt.</span>
          </span>
        </p>
        <p class="MsoNormal">
          <b>
            <i>
              <span
                >Wegen Brandgefahr ist es verboten: Kraftstoff, Öl und sonstige
                Stoffe aufzubewahren sowie um- und aufzufüllen, leere
                Kraftstoff- und Ölbehälter zu lagern, Putzlappen und Putzwolle
                aufzubewahren, Gegenstände, die wegen Undichtigkeit Brennstoff
                und Öl verlieren, abzustellen. Alle technischen und behördlichen
                Vorschriften, insbesondere der Feuerwehr und der
                Bauaufsichtsbehörde sind zu befolgen. Die Lüftungsanlagen der
                Mieteinheit dürfen nicht verschlossen werden.</span
              >
            </i>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <span>&nbsp;</span>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>3.) Mietzeit und Kündigung</span>
            </u>
          </b>
        </p>
        <p v-if="getStorage()" class="MsoNormal">
          <span>a. Das Mietverhältnis beginnt am </span>
          <b>
            <span> {{ getFormattedDate(getStorage().rentStart) }}</span>
          </b>
          <span> und</span>
          <b>
            <span>
              {{
                getStorage().rentEnd
                  ? 'endet am ' + getFormattedDate(getStorage().rentEnd)
                  : ''
              }}</span
            >
          </b>
          <span
            style="
              font-size: 10pt;
              font-family: 'Arial', sans-serif;
              color: black;
            "
          />
          <span v-if="!getStorage().rentEnd">
            verlängert sich jeweils monatlich stillschweigend um einen weiteren
            Monat wenn nicht 14 Tage vor Ablauf des Monats gekündigt wird. Die
            Kündigung bedarf der Schriftform (auch SMS/@Mail möglich).
          </span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>
                b. Kommt der Mieter mit der Zahlung von zwei Monatsmieten oder
                eines nicht unerheblichen Teils der Miete in Verzug, so steht
                dem Vermieter das Recht zur fristlosen Kündigung des
                Mietverhältnisses zu. In diesem Fall ist der Vermieter
                berechtigt, sein Vermieterpfandrecht geltend zu machen und
                hierfür die Mietsache zu betreten, die eingebrachten oder
                vorübergehend eingestellten Sachen des Mieters in Besitz zu
                nehmen und zu verwerten, nachdem der Vermieter dem Mieter zuvor
                die Verkaufsabsicht unter Bezifferung des Geldbetrages, wegen
                dessen der Verkauf stattfinden soll, angedroht hat und hiernach
                ein Zeitraum von einem Monat verstrichen ist, ohne dass die
                ausstehende Forderung vollständig erfüllt wurde. Nach
                Fristablauf ist der Vermieter auch berechtigt, die eingelagerten
                Gegenstände auf Kosten des Mieters zu entsorgen.
              </span>
            </u>
          </b>
        </p>
        <div class="html2pdf__page-break" />
        <br /><br />
        <span style="text-decoration: none">&nbsp;</span>
        <p class="MsoNormal">
          <b>
            <u>
              <span>4.) Miete und Kaution</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p
          v-if="getStorage()"
          style="font-size: 9pt; font-family: 'Arial', sans-serif; color: black"
        >
          <span>Miete: </span>
          <span>
            <b>{{ getStorage().price.toFixed(2) }} € </b>
          </span>
          <span>zzgl.19% Mwst: </span>
          <span>
            <b>{{ (getStorage().price * 0.19).toFixed(2) }} € </b>
          </span>
          <span>gesamt mtl.: </span>
          <span>
            <b>{{ (getStorage().price * 1.19).toFixed(2) }} €</b>
          </span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <span
            style="
              font-size: 10pt;
              font-family: 'Arial', sans-serif;
              color: black;
            "
            >Hiermit ermächtige/n ich/wir GSM lagermeister GmbH & Co.KG
            widerruflich Ihre Forderungen aus dem obg. Mietverhältnis bei
            Fälligkeit zu Lasten meines/unseres Konto mit der</span
          >
        </p>
        <p class="MsoNormal">
          <span>IBAN _________________________________________________</span>
        </p>
        <p class="MsoNormal">
          <span
            >BIC/SWIFT _________________________________________________</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >Kreditinstitut
            _________________________________________________</span
          >
        </p>

        <p class="MsoNormal">
          <span
            >durch Lastschrift einzuziehen. Wenn mein/unser Konto die
            erforderliche Deckung nicht aufweist, besteht seitens des
            kontoführenden Kreditinstitutes keine Verpflichtung zur Einlösung.
            Sollte dieser Fall eintreten, fallen zusätzliche Bank-/und
            Bearbeitungsgebühren an, diese werden in Rechnung gestellt.
            Teileinlösungen werden im Lastschriftverfahren nicht vorgenommen.
          </span>
        </p>

        <p class="MsoNormal">
          <b>
            <span>&nbsp;</span>
          </b>
        </p>

        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>5.) Haftung</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >Schadenersatzansprüche des Mieters gleich welcher Art
            einschließlich solcher aus vorvertraglichem Bereich und unerlaubter
            Handlung sind beschränkt auf Fälle vorsätzlicher und grob
            fahrlässiger Pflichtverletzung durch den Vermieter oder seiner
            Erfüllungs- und Verrichtungsgehilfen.</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >Für leichte Fahrlässigkeit - auch seiner Erfüllungs- und
            Verrichtungsgehilfen - haftet der Vermieter nur, wenn dadurch eine
            ihm obliegende Kardinalpflicht nachhaltig verletzt wird. Eine
            Kardinalpflicht ist eine Pflicht, deren Erfüllung die ordnungsgemäße
            Durchführung des Vertrags überhaupt erst ermöglicht und auf deren
            Einhaltung der Vertragspartner regelmäßig vertrauen darf. In solchen
            Fällen ist die Haftung des Vermieters beschränkt auf den
            typischerweise entstehenden vorhersehbaren und unmittelbaren
            Schaden. Sonstige Haftungsbeschränkungen des Vermieters bleiben
            unberührt. Eine Haftung des Vermieters ist ebenfalls ausgeschlossen
            für durch Feuer, Rauch, Ruß, Schnee, Wasser, Schwamm und allmähliche
            Einwirkung von Feuchtigkeit entstehende Schäden, es sei denn, dass
            die Schäden durch grobe Vernachlässigung der Mietsache entstanden
            sind und der Vermieter es trotz rechtzeitiger Anzeige und
            Aufforderung des Mieters unterlassen hat, innerhalb angemessener
            Frist die Mängel zu beseitigen. Die weitergehende Haftung des
            Vermieters gemäß § 536a BGB wegen anfänglicher Mängel wird
            ausdrücklich ausgeschlossen, soweit sie nicht auf Vorsatz oder
            grober Fahrlässigkeit beruht. Die Haftung des Vermieters wegen
            Arglist gemäß § 536 d BGB bleibt unberührt.</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >Der Mieter haftet für jede schuldhafte Beschädigung der Mietsache
            und des Lagergebäudes sowie sämtlicher zum Lagergebäude gehörenden
            Anlagen und Einrichtungen, die der Mieter, seine Angehörigen, seine
            Mitarbeiter, seine Untermieter oder sonstige Personen verursachen,
            die auf seine Veranlassung mit der Mietsache oder dem Lagergebäude
            in Berührung kommen.</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >Störungen des Mietgebrauchs durch andere Mieter oder sonstige
            Dritte (z.B. durch Verkehrsumleitungen, Ausgrabungen,
            Straßensperrungen, Geräusch-, Geruchs- oder Staubbelästigungen oder
            ähnliches) begründen unabhängig vom Ausmaß keinen Fehler der
            Mietsache, soweit sie nicht vom Vermieter aufgrund grober
            Fahrlässigkeit oder Vorsatzes zu vertreten sind. Der Vermieter wird
            sich jedoch bemühen, auf die Beseitigung ihm bekanntgegebener
            Störungen hinzuwirken. Die aufgeführten Haftungsbeschränkung
            beziehen sich nicht auf fahrlässig oder vorsätzlich verursachte
            Schäden aus der Verletzung des Lebens, des Körpers oder der
            Gesundheit.</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >Der Vermieter empfiehlt dem Mieter, die eingebrachten Sachen gegen
            Entwendung, Beschädigung, Verlust etc. in ausreichender Höhe zu
            versichern. Dem Mieter ist bekannt, dass der Vermieter eine solche
            Versicherung zugunsten des Mieters
            <b>
              <u>nicht abgeschlossen</u>
            </b>
            hat.
          </span>
        </p>
        <div class="html2pdf__page-break" />
        <br /><br />
        <p class="MsoNormal">
          <b>
            <u>
              <span>
                <span style="text-decoration: none">&nbsp;</span>
              </span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>6.) Betreten der Mietsache</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >Dem Vermieter oder von ihm Beauftragten ist das Betreten der
            Mietsache aus wichtigem Grund jederzeit, ansonsten nach
            rechtzeitiger Ankündigung gestattet.</span
          >
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>
                <span style="text-decoration: none">&nbsp;</span>
              </span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <u>
              <span>7.) Ansprüche bei Beendigung des Mietverhältnisses</span>
            </u>
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >a. Bei Beendigung des Mietverhältnisses ist die Mietsache in dem
            Zustand zurückzugeben, in dem sie sich bei Beginn des
            Mietverhältnisses befand. Der Mieter hat insbesondere alle
            eingebrachten Sachen zu entfernen und etwaig notwendige
            Schönheitsreparaturen sowie Instandhaltungsreparaturen auf eigene
            Kosten durchzuführen, soweit es sich um Schäden innerhalb der
            Mieträume, die aus dem Risikobereich des Mieters stammen,
            handelt.</span
          >
        </p>
        <p class="MsoNormal">
          <span
            >b. Sofern der Mieter bei Beendigung des Mietverhältnisses die
            Mietsache ganz oder teilweise nicht räumt, ist der Vermieter
            berechtigt, die zurückgelassenen Sachen auf Kosten des Mieters
            einlagern zu lassen oder auf Kosten des Mieters zu entsorgen oder
            auf Kosten des Mieters zu verwerten.
          </span>
        </p>
        <p class="MsoNormal">
          <b>
            <span>&nbsp;</span>
          </b>
        </p>
        <p class="MsoNormal">
          <b>
            <span>8.) Versicherungsschutz</span>
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >Dem Mieter ist bekannt, dass der Vermieter Versicherungen zugunsten
            des Mieters
            <b>
              <u>nicht abgeschlossen</u>
            </b>
            hat. Der Vermieter rät dem Mieter Versicherungen
          </span>
        </p>
        <p class="MsoNormal">
          <span
            >auf eigene Kosten
            <b>
              <u>separat</u>
            </b>
            abzuschließen.
          </span>
        </p>
        <p class="MsoNormal">
          <b>
            <span>&nbsp;</span>
          </b>
        </p>

        <p class="MsoNormal">
          <b>
            <span>9.) Sonstiges</span>
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >Sollte eine Bestimmung dieses Vertrages ganz oder teilweise
            unwirksam sein oder werden, so soll der Bestand der übrigen
            Bestimmungen hierdurch nicht berührt werden. Vielmehr soll an Stelle
            der unwirksamen Bestimmung eine solche wirksame treten, die dem
            ursprünglichen Parteiwillen in wirtschaftlicher Hinsicht weitgehend
            entspricht. Dasselbe soll gelten, wenn eine regelungsbedürftige
            Vertragslücke offenbar wird.</span
          >
        </p>
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <b>
            <span
              >10.) Allgemeine Geschäftsbedingungen (AGB)+Datenschutzerklärung
              (DSVO)</span
            >
          </b>
        </p>
        <p class="MsoNormal">
          <span
            >Die AGB`s und DSVO sind Bestandteil des Vertrages –diese wurde auf
            der Homepage-eingesehen.</span
          >
        </p>
        <p class="MsoNormal">
          <span>
            <b />
          </span>
        </p>
        <!-- <p class="MsoNormal">
          <span />
          <span>
            <b>1</b>
          </span>
          <span> Containerschloss+Schlüssel </span>
          <b>
            <span> 1</span>
          </b>
          <span> Torfernbedienung Kaution </span>
          <b>
            <u>
              <span>200 €</span>
            </u>
          </b>
          <span />
          <span />
        </p> -->
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <span>&nbsp;</span>
        </p>
        <p class="MsoNormal">
          <span>Münster,</span>
        </p>
        <p class="MsoNormal">
          <b>
            <span />
          </b>
        </p>
        <span>
          <img
            v-if="offer.customerSignatureImage"
            width="300"
            :src="offer.customerSignatureImage"
          />
        </span>
        <span>
          <img
            v-if="offer.landlordSignatureImage"
            width="300"
            :src="offer.landlordSignatureImage"
          />
          <img
            style="margin-left: 350px"
            v-else
            width="180"
            src="@/assets/images/pages/contract/unterschrift-SG.png"
          />
        </span>
        <div>
          <span>__________________________________________</span>
          <span style="padding-left: 35px"
            >__________________________________________</span
          >
        </div>
        <p class="MsoNormal">
          <span> Unterschrift Mieter/in</span
          ><span style="padding-left: 220px">Unterschrift Vermieter/in</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import helper from '@/helper/helper'
import storageHelper from '@/helper/storageHelper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const { getStorageSumPrice } = storageHelper()

    return { getFormattedDate, getStorageSumPrice }
  },
  props: ['offer', 'pdfStoragePrintNumber'],

  data() {
    return {}
  },

  computed: {},

  methods: {
    displayStorageInformation() {},
    // get storage from offer with pdfStoragePrintNumber
    getStorage() {
      return this.offer.storages[this.pdfStoragePrintNumber]
    },
  },
}
</script>
<style scoped lang="scss">
@font-face {
  font-family: 'Cambria Math';
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Tahoma;
  panose-1: 2 11 6 4 3 5 4 4 2 4;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  font-size: 9pt;
  font-family: 'Arial', sans-serif;
  color: black;
  line-height: 1.5;
}
p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
  margin: 0cm;
  font-size: 12pt;
  font-family: 'Times New Roman', serif;
}
p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
  margin: 0cm;
  font-size: 12pt;
  font-family: 'Times New Roman', serif;
}
a:link,
span.MsoHyperlink {
  color: blue;
  text-decoration: underline;
}
/* Page Definitions */
@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 56.75pt 70.85pt 2cm 70.85pt;
}
div.WordSection1 {
  page: WordSection1;
}
</style>
