<template lang="">
  <div>
    <!-- {{ booking }}
    {{ offer }} -->

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :float-layout="false"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Buchung.pdf"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
    >
      <!-- <booking-summery
        slot="pdf-content"
        ref="bookingSummery"
        :booking="booking"
      /> -->
      <contract-lagermeister
        slot="pdf-content"
        :offer="offer"
        :pdf-storage-print-number="pdfStoragePrintNumber"
      />
    </vue-html2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf'
import helper from '@/helper/helper'
import ContractLagermeister from './ContractLagermeister.vue'

export default {
  setup() {
    const { getFormattedDate } = helper()

    return { getFormattedDate }
  },
  components: {
    VueHtml2pdf,
    ContractLagermeister,
  },
  props: ['offer', 'pdfStoragePrintNumber'],
  data() {
    return {
      storageIndex: 0,
      pdfBookingFile: null,
    }
  },
  mounted() {
    this.generateBookingPDF()
  },

  methods: {
    generateBookingPDF() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress(event) {
      console.log(`Processed: ${event} / 100`)
    },

    startPagination() {
      console.log('Started PDF generation!')
    },

    hasPaginated() {
      console.log('PDF has pagination!')
    },

    hasGenerated(event) {
      console.log('PDF has generated!')
      console.log(event)
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      const pdfBlobUrl = await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(
              `Seite ${i} von ${totalPages}`,
              pdf.internal.pageSize.getWidth() * 0.88,
              pdf.internal.pageSize.getHeight() - 0.3
            )
          }
        })
        .output('bloburl')

      const res = await fetch(pdfBlobUrl)
      const blobFile = await res.blob()
      this.$emit('contractPDFUpdate', blobFile)
    },

    hasDownloaded(event) {
      console.log('PDF download successfully!')
      console.log(event)
      this.saveContractPDF(event)
    },

    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      // eslint-disable-next-line operator-linebreak
      const byteString =
        splitDataURI[0].indexOf('base64') >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
  },
}
</script>
<style lang=""></style>
