<template lang="">
  <div>
    <!-- {{ inquiry }} -->
    <validation-observer ref="simpleRules">
      <form-wizard
        ref="wizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Angebotsvorschau"
        back-button-text="Zurück"
        next-button-text="Weiter"
        class="wizard-vertical mb-3"
        @on-complete="showBookingPreview"
      >
        <tab-content title="Kundendetails">
          <OfferCustomerDetails :inquiry="inquiry" />
        </tab-content>
        <tab-content title="Lager">
          <move-in-storage-selection :inquiry="inquiry" />
        </tab-content>
        <tab-content title="Angebot">
          <!-- {{ order }} -->
          <move-in-make-booking :inquiry="inquiry" />
        </tab-content>
      </form-wizard>
    </validation-observer>
    <b-modal
      id="modal-booking"
      centered
      ok-only
      ok-title="Accept"
      :hide-footer="true"
      :hide-header="true"
      :hide-header-close="true"
      size="sm"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div class="d-flex justify-content-center mt-1 mb-1">
        <b-spinner label="Loading..." />
      </div>
      <div class="d-flex justify-content-center mb-1">
        {{ spinnerProgressLabel }}
      </div>
    </b-modal>
    <b-modal
      id="modal-booking-danger"
      ok-only
      ok-variant="danger"
      ok-title="Ok"
      modal-class="modal-danger"
      centered
      title="Upps, Fehler :("
    >
      <b-card-text>
        Im Angebotsprozess ist ein Fehler aufgetreten. Bitte versuchen Sie es
        erneut.
      </b-card-text>
      <b-button v-b-modal.modal-booking-errorcode variant="danger">
        Fehlercode anzeigen!
      </b-button>
    </b-modal>

    <b-modal
      id="modal-preview"
      scrollable
      title="Vertragsvorschau"
      cancel-title="Abbrechen"
      ok-variant="success"
      ok-title="Angebot durchführen"
      cancel-variant="outline-secondary"
      modal-class="modal-success"
      size="xl"
      @ok="formSubmitted"
    >
      <b-tabs>
        <b-tab
          v-for="(storage, index) in this.offer.storages"
          :key="index"
          :title="storage.storageIdent"
        >
          <b-card-text>
            <preview-contract-view
              :offer="offer"
              :pdf-storage-print-number="index"
              @contractPDFUpdate="updateContractArray"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { required } from '@validations'
import MoveInStorageSelection from '@/views/pages/movein/MoveInStorageSelection.vue'
import MoveInMakeBooking from '@/views/pages/movein/MoveInMakeBooking.vue'
import Offer from '@/store/offer/Offer'
import {
  BButton,
  BModal,
  VBModal,
  BSpinner,
  BTabs,
  BCardText,
  BTab,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueHtml2pdf from 'vue-html2pdf'
import PreviewContractView from '@/views/pages/movein/PreviewContractView.vue'
import sendEmail from '@/service/emailService'
import helper from '@/helper/helper'
import emailStoragesBody from '@/helper/emailStoragesBody'
import OfferCustomerDetails from './OfferCustomerDetails.vue'
import ContractLagermeister from '../movein/ContractLagermeister.vue'

export default {
  setup() {
    const { getFormattedDate } = helper()

    return { getFormattedDate }
  },
  components: {
    OfferCustomerDetails,
    MoveInStorageSelection,
    MoveInMakeBooking,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    Offer,
    BButton,
    BModal,
    VBModal,
    BSpinner,
    ToastificationContent,
    VueHtml2pdf,
    ContractLagermeister,
    PreviewContractView,
    BTabs,
    BCardText,
    BTab,
    emailStoragesBody,
  },
  data() {
    return {
      inquiry: {
        customer: this.$route.params.booking ? this.$route.params.booking : {},
        selectedStorages: [],
        offerNumber: '',
        offerDate: '',
        contractArray: [],
      },
      spinnerProgressLabel: '',
      errorcode: '',
      required,
      pdfBookingFile: null,
      pdfStoragePrintNumber: 0,
      offer: Offer,
    }
  },
  methods: {
    updateContractArray(pdf) {
      console.log('updateContractArray')
      this.inquiry.contractArray.push(pdf)
      console.log(this.inquiry.contractArray)
    },

    offerMapping() {
      this.offer.firstName = this.inquiry.customer.firstName
      this.offer.lastName = this.inquiry.customer.lastName
      this.offer.email = this.inquiry.customer.email
      this.offer.salutation = this.inquiry.customer.salutation
      this.offer.street = this.inquiry.customer.street
      this.offer.streetNumber = this.inquiry.customer.streetNumber
      this.offer.zipCode = this.inquiry.customer.zipCode
      this.offer.city = this.inquiry.customer.city
      this.offer.phoneNumber = this.inquiry.customer.phoneNumber
      this.offer.company = this.inquiry.customer.company
      this.offer.offerDate = Date.now()
      this.offer.storages = this.inquiry.selectedStorages
      this.offer.inquiry = this.inquiry.customer.id
      this.offer.isActive = true
    },

    showBookingPreview() {
      // empty this.booking.contractArray
      this.inquiry.contractArray = []

      this.offerMapping()
      this.$bvModal.show('modal-preview')
    },

    // ############################# PDF #############################
    generateBookingPDF() {
      this.offerMapping()
      this.$refs.html2Pdf.generatePdf()
    },

    saveContractPDF(pdf) {
      // alert('Dialog OK')

      const formData = new FormData()
      formData.append('files', pdf)

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.$store
        .dispatch('addFile', formData, config)
        .then(respond => {
          const attachmentWithFiles = {
            note: 'Vertragsunterlagen',
            customer: respond.id,
            attachment_type: 3,
            created_by: 'EK',
            updated_by: 'EK',
            files: respond.map(a => a.id),
          }

          this.$store
            .dispatch('addAttachment', attachmentWithFiles)
            .then(() => {
              // this.$router.go()
            })
        })
        .catch(() => {})
    },

    beforeTabSwitch() {
      return this.$refs.simpleRules.validate().then(success => {
        if (success) {
          return true
        }
        return true
      })
    },

    formSubmitted() {
      this.spinnerProgressLabel = 'Fange mit der Magie an...'
      this.$bvModal.show('modal-booking')

      this.$store
        .dispatch('addOffer', this.offer)
        .then(offer => {
          console.log(offer)
          this.$store.dispatch('getStorageEntries').then(() => {
            // loop through all storages and dispatch updateStorage
            this.offer.storages.forEach(storage => {
              const storageById = this.$store.getters.storageById(storage.id)
              storageById.storageReservation = true
              storageById.rentStart = storage.rentStart
              storageById.rentEnd = storage.rentEnd
              this.$store.dispatch('updateStorage', storageById).then(() => {
                this.$store.dispatch('getStorageEntries').then(() => {
                  this.$store.dispatch('getOfferEntries')
                  this.successNextSteps(offer)
                })
              })
            })
          })
        })
        .catch(error => {
          console.log(error)
          this.failureNextSteps()
        })
    },

    successNextSteps(offer) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Angebot erfolgreich erstellt!',
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      this.onSendEmail(offer)

      this.$bvModal.hide('modal-booking')
      this.$router.push({
        name: 'offers-list',
      })
    },
    failureNextSteps(error) {
      console.log('Angebot erstellen klappt nicht! Fehlercode:', error)
      this.errorcode = error
      this.$bvModal.show('modal-booking-danger')
    },

    async onSendEmail(offer) {
      const emailType = 'offer'
      const emailBody = emailStoragesBody(offer.storages, emailType)

      const email = {
        to: offer.email,
        subject: 'Der Lagermeister',
        text: emailBody,
        attachments: this.inquiry.contractArray,
        template_id: 'd-5fa2a6f835f5410f8a68037a9b212982',
        dynamic_template_data: {
          salutation: offer.salutation,
          lastName: offer.lastName,
          offerDate: this.getFormattedDate(offer.offerDate),
          offerID: offer.id,
          offerItems: emailBody,
        },
      }

      const result = await sendEmail(email)

      if (result.success) {
        alert(result.message)
      } else {
        alert(result.message)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
