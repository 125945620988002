<template lang="">
  <div>
    <!-- <div v-if="booking">
      {{ this.booking.selectedStorages }}
    </div>

    <div v-if="inquiry">
      {{ this.inquiry.selectedStorages }}
    </div> -->

    <!-- ANFRAGENACHRICHT -->
    <!--  -->
    <b-card v-if="inquiry" title="Lageranfrage">
      <b-row>
        <b-col>
          <b-form-group label-for="v-message">
            <b-form-textarea
              id="textarea-plaintext"
              rows="6"
              plaintext
              :value="this.showJSON()"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Standort">
      <b-row class="pb-1">
        <b-col sm="12" md="12" lg="8" xl="5">
          <b-form-group label-for="v-storagelocation">
            <v-select
              v-model="selectedStorageLocation"
              label="name"
              :clearable="false"
              :options="this.$store.getters.locationsEntries"
              @input="getMovedOutStorages"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- LAGEREINHEITEN -->
    <b-card
      :title="'Verfügbare Lagereinheiten (' + availableStorages.length + ')'"
    >
      <b-row>
        <b-col md="3">
          <label>Lagereinheit</label>
        </b-col>
        <b-col md="1" style="width: 12.5%">
          <label>Typ</label>
        </b-col>
        <b-col col="2">
          <label>Startdatum</label>
        </b-col>
        <b-col md="2">
          <label> <div class="">Enddatum. Leer für unbest. Zeit.</div></label>
        </b-col>
        <b-col md="2">
          <label>Preis €</label>
        </b-col>
        <b-col md="2">
          <label>Rabatt €</label>
        </b-col>
      </b-row>

      <b-row
        v-for="(storage, itemIndex) in availableStorages"
        :key="itemIndex"
        class="pb-1"
      >
        <b-col md="3">
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-form-checkbox
                name="checkbox-input"
                :value="storage"
                :unchecked-value="storage"
                @change="selectedBookingStorages"
              />
            </b-input-group-prepend>
            <b-form-input :value="storage.name" readonly />
          </b-input-group>
        </b-col>
        <b-col md="1" style="width: 12.5%">
          {{ storageTypeValue(storage) }}
        </b-col>
        <b-col md="2">
          <flatpickr
            v-model="storage.rentStart"
            class="form-control"
            :config="{
              enableTime: true,
              altInput: true,
              altFormat: 'd-M-Y - H:i \\U\\h\\r',
              dateFormat: 'Z',
              locale: {
                firstDayOfWeek: 1, // start week on Monday
              },
              time_24hr: true,
              // defaultDate: '24:00',
              defaultHour: '00',
              defaultMinute: '00',
            }"
          />
        </b-col>
        <b-col md="2">
          <flatpickr
            v-model="storage.rentEnd"
            class="form-control"
            :config="{
              enableTime: true,
              altInput: true,
              altFormat: 'd-M-Y - H:i \\U\\h\\r',
              dateFormat: 'Z',
              locale: {
                firstDayOfWeek: 1, // start week on Monday
              },
              time_24hr: true,
              // defaultDate: '24:00',
              defaultHour: '00',
              defaultMinute: '00',
            }"
          />
        </b-col>

        <b-col md="2">
          <b-input-group append="€">
            <b-form-input id="v-price" v-model.number="storage.price" />
          </b-input-group>
        </b-col>

        <b-col md="2">
          <b-input-group append="€">
            <b-form-input
              id="v-discount"
              v-model.number="storage.discount"
              placeholder="0.00"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import storageHelper from '@/helper/storageHelper'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'flatpickr/dist/flatpickr.min.css'
import flatpickr from 'flatpickr'
import { German } from 'flatpickr/dist/l10n/de'
import Vueflatpickr from 'vue-flatpickr-component'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'

flatpickr.localize(German)

export default {
  components: {
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BFormDatepicker,
    BFormGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatpickr: Vueflatpickr,
  },
  props: ['booking', 'inquiry'],
  setup() {
    const { isStorageBooked } = storageHelper()

    return { isStorageBooked }
  },
  data() {
    return {
      selectedStorageLocation: 'Standort auswählen...',
      availableStorages: [],
      storageJSON: {
        id: '',
        type: '',
        priceStorage: '',
        numberStorage: '',
        estimatedEndRent: '',
      },
      inquiryStorageArray: [],
      inquiryStorageString: '',
      whatami: 'booking',
    }
  },
  computed: {},

  mounted() {
    console.log('Inquiry: ', this.inquiry)
    this.selectedStorageLocation = this.$store.getters.locationById(
      // eslint-disable-next-line radix
      parseInt(this.inquiry.customer.storageLocation)
    )

    this.getMovedOutStorages(this.selectedStorageLocation)
  },

  methods: {
    storageTypeValue(storage) {
      return this.$store.getters.getStorageTypesById(storage.storage_type).name
    },
    getMovedOutStorages(location) {
      console.log('Location')
      console.log(location)

      this.availableStorages = []

      for (let index = 0; index < location.storages.length; index += 1) {
        const storage = location.storages[index]

        if (this.isStorageBooked(storage).value === false) {
          this.availableStorages.push(storage)
        }
      }
    },

    getAvailableStorages(location) {
      this.availableStorages = []

      for (let index = 0; index < location.storages.length; index += 1) {
        const storage = location.storages[index]

        if (this.isStorageBooked(storage).value === false) {
          this.availableStorages.push(storage)
        }
      }
    },
    selectedBookingStorages(storage) {
      let whatami = this.booking

      if (this.inquiry) {
        whatami = this.inquiry
      }

      if (!whatami.selectedStorages.includes(storage)) {
        // checking weather array contain the id

        if (whatami === this.inquiry) {
          // eslint-disable-next-line no-param-reassign
          storage.storageReservation = true
        }

        whatami.selectedStorages.push(storage) // adding to array because value doesnt exists
      } else {
        if (whatami === this.inquiry) {
          // eslint-disable-next-line no-param-reassign
          storage.storageReservation = false
        }

        whatami.selectedStorages.splice(
          whatami.selectedStorages.indexOf(storage),
          1
        ) // removing from array because value exists
      }
    },
    showJSON() {
      let inquiryStorageString = ''

      this.inquiry.customer.storageJSON.storage.forEach(storage => {
        inquiryStorageString += `
        ${storage.type} | Anzahl: ${storage.numberStorage} | Preis: ${
          storage.priceStorage
        }
        Standort: ${
          this.$store.getters.locationById(Number(storage.location)).name
        }
        Gewünschter Start: ${storage.estimatedStartRent} | Gewünschtes Ende: ${
          storage.estimatedEndRent
        }\n
        `
      })

      return inquiryStorageString
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
