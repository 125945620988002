<template lang="">
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="this.PDFLayout"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <booking-summery
        v-if="this.booking"
        slot="pdf-content"
        ref="bookingSummery"
        :booking="booking"
        :parent-route="parentRoute"
      />

      <offer-summery
        v-if="this.inquiry"
        slot="pdf-content"
        ref="offerSummery"
        :inquiry="inquiry"
      />
    </vue-html2pdf>

    <b-row v-if="this.booking && !parentRoute" class="match-height">
      <b-col>
        <b-card>
          <b-button
            variant="danger"
            class="btn-icon"
            style="float: right"
            @click="$refs.bookingSummery.clearSignature()"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          v-if="this.booking"
          title="Buchung durchführen?"
          class="position-static"
        >
          <b-card-text>
            Mit dem Absenden wird ein eine Buchung im System generiert und dem
            Kunden die ausgewählte(n) Lagereinheit(en) zugeordnet!
          </b-card-text>
        </b-card>
        <b-card
          v-if="this.inquiry"
          title="Angebot erstellen?"
          class="position-static"
        >
          <b-card-text>
            Mit dem Absenden wird ein ein Angebot erstellt und dem Interessent
            per Email versendet.
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import VueHtml2pdf from 'vue-html2pdf'
import BookingSummery from './BookingSummery.vue'
import OfferSummery from './OfferSummery.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BCardText,
    BButton,
    BookingSummery,
    OfferSummery,
    VueHtml2pdf,
  },
  props: ['booking', 'inquiry', 'parentRoute'],
  data() {
    return {
      PDFLayout: false,
    }
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    showPDFLayout() {
      this.PDFLayout = !this.PDFLayout
    },
  },
}
</script>
<style>
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
